import {
  TechRadarApi,
} from '@backstage-community/plugin-tech-radar';

import data from './data.json'

export class StaticTechRadarClient implements TechRadarApi {
  async load(_id: string | undefined) {
    return {
      quadrants: [
        { id: "1", name: "Languages" },
        { id: "2", name: "Platforms, Infra, Cyber & Ops" },
        { id: "3", name: "Techniques, Frameworks & Tools" },
        { id: "4", name: "Data Management" },
      ],
      rings: [
        { id: "1", name: "ADOPT", color: "#93c47d" },
        { id: "2", name: "TRIAL", color: "#93d2c2" },
        { id: "3", name: "ASSESS", color: "#fbdb84" },
        { id: "4", name: "HOLD", color: "#efafa9" },
      ],
      entries: data.map((item) => {
        return {
          key: item.label,
          id: item.label,
          quadrant: (item.quadrant + 1).toString(),
          title: item.label,
          timeline: [
            {
              ringId: (item.ring + 1).toString(),
              date: new Date(),
              moved: item.moved
            }
          ]
        };
      })
    };
  }
}
